<template>
  <div>
    <div v-if="!isMobile">
      <div class="cover" v-if="collaborateVisible">
        <div class="collaborate">
          <img
            src="@/assets/public/弹框关闭.png"
            alt=""
            @click="collaborateVisible = false"
          />
          <div class="title">{{ source ? "委托找仓" : "我要合作" }}</div>
          <div class="subtitle">请完善信息，我们会根据您的详细情况帮您寻找合适的云仓</div>

          <el-form
            ref="form"
            :model="form"
            label-width="80px"
            label-position="top"
            :rules="rules"
          >
            <el-form-item label="联系人" prop="linkman">
              <el-input v-model="form.linkman" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="mobile">
              <el-input v-model="form.mobile" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="品类需求" prop="mainGoods">
              <el-input v-model="form.mainGoods" placeholder="请输入品类需求"></el-input>
            </el-form-item>
            <el-form-item label="日均单量" prop="dayDeliveringAmount">
              <el-radio-group v-model="selectedRangeLowerBound">
                <el-radio :label="100" class="schemeFour3">100及以下</el-radio>
                <el-radio :label="200" class="schemeFour3">200~500单</el-radio>
                <el-radio :label="500" class="schemeFour3">500~2000单</el-radio>
                <el-radio :label="2000" class="schemeFour3">2000~5000单</el-radio>
                <el-radio :label="5001" class="schemeFour3">5000单以上</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <ButtonStyle
            :arrow="false"
            title="提交信息"
            :state="false"
            @click.native="submitInformation"
          />
          <div class="iphone">400-6280-680</div>
          <div class="notes">蜂腾24小时全国服务热线</div>
        </div>
      </div>
    </div>

    <div v-if="isMobile">
      <div class="coverBox" v-if="collaborateVisible">
        <div class="collaborate">
          <img
            src="@/assets/public/弹框关闭.png"
            alt=""
            @click="collaborateVisible = false"
          />
          <div class="title">{{ source ? "委托找仓" : "我要合作" }}</div>
          <div class="subtitle">请完善信息，我们会根据您的详细情况帮您寻找合适的云仓</div>

          <el-form
            ref="form"
            :model="form"
            label-width="80px"
            label-position="top"
            :rules="rules"
          >
            <el-form-item label="联系人" prop="linkman">
              <el-input v-model="form.linkman" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="mobile">
              <el-input v-model="form.mobile" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="品类需求" prop="mainGoods">
              <el-input v-model="form.mainGoods" placeholder="请输入品类需求"></el-input>
            </el-form-item>
            <el-form-item label="日均单量" prop="dayDeliveringAmount">
              <el-radio-group v-model="selectedRangeLowerBound">
                <el-radio :label="100" class="schemeFour3">100及以下</el-radio>
                <el-radio :label="200" class="schemeFour3">200~500单</el-radio>
                <el-radio :label="500" class="schemeFour3">500~2000单</el-radio>
                <el-radio :label="2000" class="schemeFour3">2000~5000单</el-radio>
                <el-radio :label="5001" class="schemeFour3">5000单以上</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div class="ButtonStyle" @click="submitInformation">提交信息</div>
          <div class="iphone">400-6280-680</div>
          <div class="notes">蜂腾24小时全国服务热线</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedRangeLowerBound: null,
      form: {
        operationType: 10,
        dailyDeliveryQtyMin: undefined,
        dailyDeliveryQtyMax: undefined,
      },
      rules: {
        linkman: [{ required: true, message: "联系人不能为空", trigger: "blur" }],
        mobile: [
          {
            required: true,
            message: "联系电话不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, cb) => {
              if (/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value)) {
                cb();
              } else {
                cb(new Error("手机号格式错误"));
              }
            },
          },
        ],
      },
      collaborateVisible: false,
    };
  },
  computed: {
    dayDeliveringAmount() {
      if (this.selectedRangeLowerBound === 100) {
        return [0, 100];
      } else if (this.selectedRangeLowerBound === 200) {
        return [200, 500];
      } else if (this.selectedRangeLowerBound === 500) {
        return [500, 2000];
      } else if (this.selectedRangeLowerBound === 2000) {
        return [2000, 5000];
      } else if (this.selectedRangeLowerBound === 5001) {
        return [5000, 10000];
      }
      return null;
    },
  },
  methods: {
    inio() {
      this.collaborateVisible = true;
    },
    submitInformation() {
      console.log("sadasdas");
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.form.dailyDeliveryQtyMin = this.dayDeliveringAmount[0];
          this.form.dailyDeliveryQtyMax = this.dayDeliveringAmount[1];
          let res = await this.$http.post(
            // "http://192.168.110.232:31888/admin-api/crm/clue-open-api/external-info-add",
            "https://crm.fengtengkeji.com/admin-api/crm/clue-open-api/external-info-add",
            this.form,
            {
              headers: {
                "tenant-id": 1,
              },
            }
          );

          if (res.data.code == 0) {
            this.$message.success("提交成功");
            this.from = {
              operationType: 10,
              dailyDeliveryQtyMin: undefined,
              dailyDeliveryQtyMax: undefined,
            };
            (this.selectedRangeLowerBound = null), (this.collaborateVisible = false);
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error("请完善信息");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 10;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  .collaborate {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 620px;
    z-index: 2;
    padding: 20px 90px;

    img {
      position: absolute;
      right: -35px;
      top: -35px;
      cursor: pointer;
      width: 38px;
      height: 38px;
    }

    .title {
      font-weight: bold;
      font-size: 30px;
      color: #fe5e00;
      text-align: center;
      margin-bottom: 30px;
    }

    .subtitle {
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 14px;
      color: #494949;
      text-align: center;
    }
    .iphone {
      width: 311px;
      height: 33px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      font-size: 42px;
      color: #fe5e00;
      margin: 20px auto 20px;
    }

    .notes {
      width: 211px;
      height: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      margin: 0 auto;
    }
  }
}

.cover ::v-deep .el-input__inner {
  color: #676767;
  background-color: #eef0f2;
  /* 你可以更换成你想要的颜色 */
  // border-color: #676767;
  /* 同样可以更换边框颜色 */
}

.coverBox {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .collaborate {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 5.88rem;
    z-index: 2;
    padding: 0.25rem 0.3rem;

    img {
      position: absolute;
      right: -0.37rem;
      top: -0.37rem;
      cursor: pointer;
      width: 0.37rem;
      height: 0.37rem;
    }

    .title {
      font-weight: bold;
      font-size: 0.3rem;
      color: #fe5e00;
      text-align: center;
      margin-bottom: 0.2rem;
    }

    .subtitle {
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 0.14rem;
      color: #494949;
      text-align: center;
      margin-bottom: 0.23rem;
    }
    .iphone {
      display: flex;
      justify-content: center;
      font-weight: 800;
      font-size: 0.42rem;
      color: #fe5e00;
      margin: 0.3rem auto 0.08rem auto;
    }

    .notes {
      font-weight: 400;
      font-size: 0.18rem;
      color: #000000;
      margin: 0 auto;
      text-align: center;
    }
    .ButtonStyle {
      width: 5.11rem;
      height: 0.75rem;
      background: #fe5e00;
      border-radius: 0.06rem;
      margin: 0 auto 0 auto;
      font-weight: bold;
      font-size: 0.2rem;
      color: #fff9f9;
      text-align: center;
      line-height: 0.75rem;
    }
  }
}

.coverBox ::v-deep .el-input__inner {
  color: #676767;
  background-color: #f5f6f7;
  font-size: 0.16rem;
  height: 0.74rem;
  line-height: 0.74rem;
  margin-bottom: 0.2rem;
  border-radius: 0.1rem;
}

.coverBox ::v-deep .el-form-item__label {
  font-size: 0.18rem;
  margin-bottom: 0.2rem;
}

.coverBox ::v-deep .el-form-item__error {
  font-size: 0.16rem;
  top: 83%;
}

.coverBox ::v-deep .el-radio__label {
  font-weight: 400;
  font-size: 0.16rem;
  color: #666666;
}

.coverBox ::v-deep .el-radio__input {
  line-height: 0.4rem;
}

.coverBox ::v-deep .el-radio__inner {
  width: 0.17rem;
  height: 0.17rem;
}

.coverBox ::v-deep .el-radio__inner::after {
  width: 0.05rem;
  height: 0.05rem;
}
</style>
