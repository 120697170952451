<template>
  <div>
    <div v-if="!isMobile">
      <div class="cover" v-if="collaborateVisible">
        <div class="collaborate">
          <img
            src="@/assets/public/弹框关闭.png"
            alt=""
            @click="collaborateVisible = false"
          />
          <div class="title">
            {{
              source == 10 ? "我要入驻" : source == 20 ? "委托找仓" : "合作加盟"
            }}
          </div>
          <div class="subtitle">请完善信息，我们会根据您的详细情况帮您入驻</div>

          <el-form
            ref="form"
            :model="form"
            label-width="80px"
            label-position="top"
            :rules="rules"
            inline
          >
            <el-form-item label="姓名" prop="personInCharge">
              <el-input
                v-model="form.personInCharge"
                placeholder="请输入联系人"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phoneNum">
              <el-input
                v-model="form.phoneNum"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="省市区" prop="regionData">
              <el-cascader
                v-model="form.regionData"
                :options="pctOptions"
                @change="handleChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <el-input
                v-model="form.address"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="仓库面积" prop="floorSpace">
              <el-input
                v-model="form.floorSpace"
                placeholder="请输入仓库面积"
              ></el-input>
            </el-form-item>
            <el-form-item label="冷库面积" prop="refHouseSpace">
              <el-input
                v-model="form.refHouseSpace"
                placeholder="请输入冷库面积"
              ></el-input>
            </el-form-item>
            <el-form-item label="详细描述" prop="remark">
              <el-input
                type="textarea"
                v-model="form.remark"
                placeholder="请输入详细描述"
                class="address"
                :autosize="{ minRows: 4, maxRows: 6 }"
                maxlength="255"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-form>
          <ButtonStyle
            :arrow="false"
            title="提交信息"
            :state="false"
            @click.native="submitInformation"
          />
          <div class="iphone">400-6280-680</div>
          <div class="notes">蜂腾24小时全国服务热线</div>
        </div>
      </div>
    </div>

    <div v-if="isMobile">
      <div class="coverBox" v-if="collaborateVisible">
        <div class="collaborate">
          <img
            src="@/assets/public/弹框关闭.png"
            alt=""
            @click="collaborateVisible = false"
          />
          <div class="title">
            {{
              source == 10 ? "我要入驻" : source == 20 ? "委托找仓" : "合作加盟"
            }}
          </div>
          <div class="subtitle">请完善信息，我们会根据您的详细情况帮您入驻</div>

          <el-form
            ref="form"
            :model="form"
            label-width="80px"
            label-position="top"
            :rules="rules"
          >
            <el-form-item label="姓名" prop="personInCharge">
              <el-input
                v-model="form.personInCharge"
                placeholder="请输入联系人"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phoneNum">
              <el-input
                v-model="form.phoneNum"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="省市区" prop="regionData">
              <el-cascader
                v-model="form.regionData"
                :options="pctOptions"
                @change="handleChange"
                popper-class="train-tree"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <el-input
                v-model="form.address"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="仓库面积" prop="floorSpace">
              <el-input
                v-model="form.floorSpace"
                placeholder="请输入仓库面积"
              ></el-input>
            </el-form-item>
            <el-form-item label="冷库面积" prop="refHouseSpace">
              <el-input
                v-model="form.refHouseSpace"
                placeholder="请输入冷库面积"
              ></el-input>
            </el-form-item>
            <el-form-item label="详细描述" prop="remark">
              <el-input
                type="textarea"
                v-model="form.remark"
                placeholder="请输入详细描述"
                class="address"
                :autosize="{ minRows: 4, maxRows: 6 }"
                maxlength="255"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-form>
           <div class="ButtonStyle" @click="submitInformation">
            提交信息
          </div>
          <div class="iphone">400-6280-680</div>
          <div class="notes">蜂腾24小时全国服务热线</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CodeToText, regionData } from "element-china-area-data";
export default {
  data() {
    return {
      form: {},
      source: null,
      rules: {
        personInCharge: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],
        phoneNum: [
          {
            required: true,
            message: "联系电话不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, cb) => {
              if (/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value)) {
                cb();
              } else {
                cb(new Error("手机号格式错误"));
              }
            },
          },
        ],
      },
      collaborateVisible: false,
      pctOptions: regionData,
    };
  },
  methods: {
    inio(source) {
      this.source = source;
      this.collaborateVisible = true;
    },
    handleChange(e) {
      console.log(e);
      this.form.province = CodeToText[e[0]];
      this.form.city = CodeToText[e[1]];
      this.form.region = CodeToText[e[2]];
    },
    submitInformation() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.post(
            // "http://192.168.110.232:31888/admin-api/crm/warehouse-source-open-api/join-in",
            "https://crm.fengtengkeji.com/admin-api/crm/warehouse-source-open-api/join-in",
            this.form,
            {
              headers: {
                "tenant-id": 1,
              },
            }
          );
          if (res.data.code == 0) {
            this.$message.success("提交成功");
            this.form = {};
            this.collaborateVisible = false;
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error("请完善信息");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .collaborate {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 800px;
    z-index: 2;
    padding: 20px 90px;

    img {
      position: absolute;
      right: -35px;
      top: -35px;
      cursor: pointer;
      width: 38px;
      height: 38px;
    }

    .title {
      font-weight: bold;
      font-size: 30px;
      color: #fe5e00;
      text-align: center;
      margin-bottom: 30px;
    }

    .subtitle {
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 14px;
      color: #494949;
      text-align: center;
    }
    .address {
      width: 595px;
    }
    .iphone {
      width: 311px;
      height: 33px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      font-size: 42px;
      color: #fe5e00;
      margin: 20px auto 20px;
    }

    .notes {
      width: 211px;
      height: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      margin: 0 auto;
    }
  }
}

.cover ::v-deep .el-textarea__inner {
  color: #676767;
  background-color: #eef0f2;
}

.cover ::v-deep .el-input__inner {
  color: #676767;
  background-color: #eef0f2;
}

.cover ::v-deep .el-textarea .el-input__count {
  color: #676767;
  background-color: #eef0f2;
}

.coverBox {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .collaborate {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 5.88rem;
    z-index: 2;
    padding: 0.1rem 0.3rem;

    img {
      position: absolute;
      right: -0.37rem;
      top: -0.37rem;
      cursor: pointer;
      width: 0.37rem;
      height: 0.37rem;
    }

    .title {
      font-weight: bold;
      font-size: 0.3rem;
      color: #fe5e00;
      text-align: center;
      margin-bottom: 0.1rem;
    }

    .subtitle {
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 0.14rem;
      color: #494949;
      text-align: center;
      margin-bottom: 0.1rem;
    }
    .iphone {
      display: flex;
      justify-content: center;
      font-weight: 800;
      font-size: 0.42rem;
      color: #fe5e00;
      margin: 0.1rem auto 0.05rem auto;
    }

    .notes {
      font-weight: 400;
      font-size: 0.18rem;
      color: #000000;
      margin: 0 auto;
      text-align: center;
    }
    .ButtonStyle {
      width: 5.11rem;
      height: 0.5rem;
      background: #fe5e00;
      border-radius: 0.06rem;
      margin: 0 auto 0 auto;
      font-weight: bold;
      font-size: 0.2rem;
      color: #fff9f9;
      text-align: center;
      line-height: 0.5rem;
    }
  }
}
.coverBox ::v-deep .el-input__inner {
  color: #676767;
  background-color: #f5f6f7;
  font-size: 0.16rem;
  height: 0.5rem;
  line-height: 0.5rem;
  margin-bottom: 0.2rem;
  border-radius: 0.1rem;
  width: 5.21rem;
}

.coverBox ::v-deep .el-form-item__label {
  font-size: 0.18rem;
}

.coverBox ::v-deep .el-form-item__error {
  font-size: 0.16rem;
  top: 83%;
}

.coverBox ::v-deep .el-radio__label {
  font-weight: 400;
  font-size: 0.16rem;
  color: #666666;
}

.coverBox ::v-deep .el-radio__input {
  line-height: 0.4rem;
}

.coverBox ::v-deep .el-radio__inner {
  width: 0.17rem;
  height: 0.17rem;
}

.coverBox ::v-deep .el-radio__inner::after {
  width: 0.05rem;
  height: 0.05rem;
}

.coverBox ::v-deep  .el-textarea__inner{
   font-size: 0.16rem;

}
</style>
